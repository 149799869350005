export default {
  primary: '#ff671b',
  green: '#28c76f',
  grey100: '#fafafa',
  grey200: '#d8d8d8',
  grey200alpha: 'rgba(216, 216, 216, 0.3)',
  grey400: '#636363',
  black: '#000000',
  halfblack: 'rgba(0, 0, 0, 0.5)',
  blackalpha50: 'rgba(0, 0, 0, 0.5)',
};

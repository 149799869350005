import { Heading } from '@lce/slice_v2';
import * as React from 'react';

export interface ISwitchLocaleProps {
  locale: string;
  text: string;
}
export const returnNewLocale = (locale: string) => {
  switch (locale) {
    case 'es-ES':
      return 'ca-es';
    case 'ca-ES':
      return 'es-es';
    case 'ar-KW':
      return 'en-kw';
    case 'en-KW':
      return 'ar-kw';
    default:
      return '';
  }
};

const SwitchLocale: React.FC<ISwitchLocaleProps> = ({ locale, text }) => {
  const handleClick = () => {
    const url = window.location.href;
    const currentLocale = locale.toLowerCase();
    const newLocale = returnNewLocale(locale);
    const newUrl = url.replace(currentLocale, newLocale);
    window.location.href = newUrl;
  };
  
  return (
    <Heading as='h2' data-testid="switch-locale" onClick={handleClick} sx={{
      fontSize: [10, 8, 10],
      m: ['8px 8px 20px 8px', '8px 4px', '8px 32px 8px 8px'],
      p: ['8px 16px', '4px 16px', '8px 16px'],
      textTransform: 'uppercase',
      cursor: 'pointer',
      '&:hover': { textDecoration: 'underline' },
    }}>
      {text}
    </Heading>
  );
};

export default SwitchLocale;

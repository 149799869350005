import * as React from 'react';
import { XCircle } from 'react-feather';
import Link from '@lce/slice_v2/Elements/Link';
import Layer, { ILayerProps } from '@lce/slice_v2/Modules/Layer';
import Box from '@lce/slice_v2/Layout/Box';
import Flex from '@lce/slice_v2/Layout/Flex';
import Heading from '@lce/slice_v2/Elements/Heading';

import * as Styled from './Modal.style';

export interface IModalProps extends ILayerProps {
  title?: string;
  closeTitle?: string;
  isOrange?: boolean;
}

const Modal: React.FC<IModalProps> = ({ title, children, closeTitle, isOrange = true, ...props }) => (
  <Layer {...props}>
    <Box data-testid="ModalBox" sx={{ ...Styled.ModalBox, backgroundColor: isOrange ? '#ff671b' : 'white' }}>
      <Flex data-testid="ModalHeader" sx={{ ...Styled.Header, color: isOrange ? 'white' : '#ff671b' }}>
        <Heading as='h2' sx={Styled.ModalTitle}>{title}</Heading>
        <Link as="button" color="inherit" data-testid="CloseButton" onClick={props.onClose} sx={Styled.Button} title={closeTitle}>
          <XCircle />
        </Link>
      </Flex>
      <Box sx={Styled.Body}>{children}</Box>
    </Box>
  </Layer>
);

export default Modal;
